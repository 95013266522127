import React from 'react';
import { Link } from 'react-router-dom';

const CourseTypeOne = ({ data, classes }) => {
    const excerpt = data.excerpt.substring(0, 120) + "...";

    return (
        <div className={`edu-card card-type-3 radius-small ${ classes ? classes : '' }`}>
            <div className="inner">
                <div className="thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/curso/${data.id}`}>
                        <img className="w-100" src={`${process.env.PUBLIC_URL}/images/course/course-thumbnail/${data.image}`} alt="Miniatura Curso" />
                    </Link>
                    <div className="top-position status-group left-bottom">
                        <Link className="eduvibe-status status-03" to={process.env.PUBLIC_URL + `/cursos`}>
                            {data.categories.slice(0, 1)}
                        </Link>
                    </div>
                </div>
                <div className="content">
                    <div className="card-top">
                        <ul className="edu-meta meta-02">
                            <li><i className="icon-file-list-3-line"></i>{data.lesson} Temas</li>
                        </ul>
                    </div>
                    <h6 className="title">
                        <Link to={process.env.PUBLIC_URL + `/curso/${data.id}`}>{data.thumbnail_title}</Link>
                    </h6>
                </div>
            </div>

            <div className="card-hover-action">
                <div className="hover-content">
                    <div className="content-top">
                        <div className="top-status-bar">
                            <Link className="eduvibe-status status-03" to={process.env.PUBLIC_URL + `/cursos`}>
                                {data.categories.slice(0, 1)}
                            </Link>
                        </div>
                    </div>

                    <h6 className="title">
                        <Link to={process.env.PUBLIC_URL + `/curso/${data.id}`}>{data.thumbnail_title}</Link>
                    </h6>

                    <p className="description">{ excerpt }</p>

                    <div className="hover-bottom-content">
                        <ul className="edu-meta meta-02">
                            <li><i className="icon-file-list-3-line"></i>{data.lesson} Temas</li>
                        </ul>
                    </div>
                    <div className="read-more-btn">
                        <Link className="edu-btn btn-medium btn-white" to={process.env.PUBLIC_URL + `/curso/${data.id}`}>
                            Ir ahora<i className="icon-arrow-right-line-right"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CourseTypeOne;